<div class="row">
  <div class="col-md-12">
    <div class="review-input-block position-fixed" style="bottom: 20px; width: 37.8%">

      @if(files){
      <div class="review-button-block">
        @for(file of files; track file) {
        <div class="uploaded-file justify-content-between align-items-center mb-2">
          <div class="d-flex">
            <img
              src="assets/images/icons/icon-pdf.svg"
              width="20"
              class="me-2"/>
            {{ file.original_name }}
          </div>
          <small class="ms-2" style="margin-top: 3px">
            {{ file.size | fileSize }}</small>

            <button
              type="button"
              class="btn btn-link text-danger ms-2"
              (click)="onDeleteFile(file)">
              <img src="assets/images/icons/icon-delete.svg" width="20" />
            </button>
        </div>
        }
      </div>
      }

      <div class="review-input-textarea mt-3">
        <textarea
          id="commentTextArea"
          placeholder="Write your comment.."
          rows="2"
          class="form-control"
          [(ngModel)]="commentText"
        >
        </textarea>
      </div>
      <div class="review-attachement-block">

      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center">
          @if(isDocumentFieldEnabled){
            <span class="custom-upload">
              <input type="file" id="file" style="display: none;" multiple (change)="onFileChange($event)">
              <label for="file" class="action-button">
                <div>
                  <img src="assets/images/icons/icon-attach-file.svg" class="d-block mx-auto cursor-pointer" alt="upload icon" />
                </div>
              </label>
            </span>
          }
          @if(isClosFieldEnable && status != 'closed'){
            <div class="form-check form-check pb-0 ms-3 mb-2">
              <label class="form-check-label">
                <input class="form-check-input" type="checkbox" id="check1" [(ngModel)]="isClosed">
                Close
              </label>
            </div>
          }
       
      </div>

        @if (!uiStore.saving) {
          <button
            type="button"
            class="btn-green-send me-2"
            [disabled]="!commentText"
            (click)="requestToSaveComment()">
            <img src="assets/images/icons/icon-send.svg" />
          </button>
        }@else if (uiStore.saving) {
          <button class="btn btn-primary btn-theme me-2" type="button" disabled>
            <app-spinner></app-spinner>
          </button>
        }
      </div>
      </div>
    </div>
  </div>
</div>
