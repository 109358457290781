import { Component, EventEmitter, Input, output, Output } from '@angular/core';
import { DiscussionPanelBodyComponent } from '../discussion-panel-body/discussion-panel-body.component';
import { DiscussionPanelCommentComponent } from '../discussion-panel-comment/discussion-panel-comment.component';
import { DiscussionPanelSubmitComponent } from '../discussion-panel-submit/discussion-panel-submit.component';
import { MainLoaderComponent } from '../../../loaders/main-loader/main-loader.component';
import { DiscussionPanelHeaderComponent } from '../discussion-panel-header/discussion-panel-header.component';
import { CommentDTO, commentFormDTO, CommentStatusFormDTO } from '../../../../dto/discussion.dto';

@Component({
  selector: 'app-discussion-panel-layout',
  standalone: true,
  imports: [
    DiscussionPanelBodyComponent,
    DiscussionPanelCommentComponent,
    DiscussionPanelSubmitComponent,
    MainLoaderComponent,
    DiscussionPanelHeaderComponent,
  ],
  templateUrl: './discussion-panel-layout.component.html',
  styleUrl: './discussion-panel-layout.component.scss',
})
export class DiscussionPanelLayoutComponent {
  //common
  @Input() isShowPanel: boolean = false;
  @Input() title: string;
  @Output() closePanelRequest = new EventEmitter<void>();

  //details
  @Input() isDetailsLoading: boolean = true;
  @Input() details: any;
  @Output() submitMainItemRequest = new EventEmitter<void>();

  //comments
  @Input() isCommentLoading: boolean = false;
  @Input() comments: CommentDTO[];
  @Output() saveCommentRequest = new EventEmitter<commentFormDTO>();
  @Output() updateCommentStatusRequest = new EventEmitter<CommentStatusFormDTO>();

  //Form
  @Input() isDocumentFieldEnabled: boolean = false;
  @Input() isClosFieldEnable: boolean = false;


  requestToSubmitMainItem() {
    this.submitMainItemRequest.emit();
  }

  requestToSaveComment(commentForm: commentFormDTO) {
    this.saveCommentRequest.emit(commentForm);
  }

  requestToClosePanel() {
    this.closePanelRequest.emit();
  }

  updateCommentStatus(commentStatusForm: CommentStatusFormDTO) {
    this.updateCommentStatusRequest.emit(commentStatusForm);
  }
}
